import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BehaviorSubject } from 'rxjs/internal/BehaviorSubject';
import { CallCenterService } from './call-center.service';
import { ToastrService } from 'ngx-toastr';
import { DatePipe } from '@angular/common';
import { CallCenterSharedService } from './call-center-shared.service';
import { Inviter, InviterInviteOptions, RegistererState, SessionState, UserAgent, Web ,UserAgentOptions } from 'sip.js';
import { HttpClient } from '@angular/common/http';
import { SipConnectionService } from './sip-connection.service'; 
import { BaseService } from 'app/core/services/base.service';
import { OutgoingInviteRequest } from 'sip.js/lib/core';
import { SubSink } from 'subsink';
import { FuseConfirmationConfig, FuseConfirmationService } from '@fuse/services/confirmation';
import { NavigationExtras, Router } from '@angular/router';
import { FireMessagingService } from 'app/core/services/fire-messaging.service';
import { NavigateAfterDidPurchaseModel } from 'app/core/models/common.models';
import { AppEventType, CommonPanelEvents, NavigateToModuleEnum } from 'app/core/enums/common.enum';
import { CommonService } from 'app/core/services/common.service';
import { sidePanelConstants } from 'app/core/constants/app-shared.constants';
import { SipInboundFunctionService } from './sip-inbound-function.service';
import { TransferCallPayload } from '../models/call-center.models';
import { AppConstants } from 'app/core/constants/app.constants';

@Injectable({
  providedIn: 'root'
})
export class SipOutboundFunctionService extends BaseService {

  public connection: any

  public allowClearCallQ: boolean = false;

  public ringAudio = new Audio();

  public declineTone = new Audio();

  public acceptTone = new Audio();

  private subs = new SubSink();

  public iscallcenterEnabled: boolean = true;

  public remoteAudio = new Audio();

  public audioInputSource: string;

  public videoInputSource: any;

  public currentCallType: string = 'contact';

  private callto_sip_uri: string = '';

  public mediaElement: any

  private number = new BehaviorSubject<string>('');

  constructor(private http: HttpClient,
    private callCenterService: CallCenterService,
    private sipConnectionService: SipConnectionService,
    private callCenterShared: CallCenterSharedService,
    private datePipe: DatePipe,
    private toastr: ToastrService,
    private _fuseConfirmDialogService: FuseConfirmationService,
    private commonService:CommonService,
    private router:Router,
    private _fireMessagingService: FireMessagingService,
    private sipInboundFunctionService: SipInboundFunctionService) {
    super();
  }

  showPurchaseDialogIfNoNumbers(toNumber: string) {
    let config:FuseConfirmationConfig = {
      title: 'Unable to Initiate Call',
      message: 'You need an active phone number to proceed with the call. Would you like to purchase one?',
      icon: {
        show: true,
        name: 'mat_outline:phone_disabled',
        color: 'warning',
      },
      actions: {
          confirm: {
              show: true,
              label: 'Buy Numbers',
              color: 'primary'
          },
          cancel: {
              show: true
          }
      },
      dismissible: false,
    }
    let dialogRef = this._fuseConfirmDialogService.open(config);
    let state: NavigateAfterDidPurchaseModel = {
      module : NavigateToModuleEnum.DIALER,
      extras: toNumber
    }
    const navigationExtras: NavigationExtras = {
      state: state
    };
    dialogRef.afterClosed().subscribe(result => {
      if (result == 'confirmed') {
        this.callCenterShared.popupOpenStatus.next(false);
        this.router.navigate(['phone-system/dids/new'],navigationExtras);
      }
    });
  }

  public async makeCall(type: string, from, to, agentsipuri: string, callto_agent_id: string = "") {  //type 'contact' or sip

    if(to.length>8 && !this.callCenterShared.sipDetails.send_as){
      this.showPurchaseDialogIfNoNumbers(to);
      return;
    }

    const hasPermission = await this.callCenterShared.requestMicrophonePermission();
    if (!hasPermission) {
      return;
    }

    if(this.callCenterShared.sipDetails.agent_name == to)
      {
        this.toastr.error('Calling to same SIP is not Possible','Error')
        this.callCenterShared.initiatedOutboundCall = false
        return
      }

    this.callCenterShared.showDialer = false
    let pushId = 'push-'+Date.now()

    this.currentCallType = to.length<=8?'sip':'contact'

    console.warn('currentCallType',this.currentCallType)

    var customValues = {
      'isCallOnHold': false, 'callerName': '', 'callFrom': from, 'isMuted': false,
      'callTo': to, 'isAgentCall': this.currentCallType == 'sip' ? true : false, 'call_to_agentid': to,
      'callType': 'outbound','elapsedTime':0, 'notification_established':true,'pushid':pushId, 'call_answered': false};

    var callerSession =  {callDetails:{'contact_name':'',
                          'incoming_route_name':'Loading..','customer_number':to,
                          'tenant_customer_name':'Loading','call_id':pushId,
                        'parent_call_id':null},
                          session:{state:'push'},customValues:customValues}

    this.pushCallerSession(callerSession)
    this.closeCommonSidePanel();

    // if (type == 'contact' && to.length > 10) {
    //   to = to.slice(to.length - 10);
    // }

    // console.log("connection on outbound",  this.callCenterShared.connection )// for test
    // const isSipconnected: boolean = this.callCenterShared.connection?.state == UserAgent.state.Started;

    console.warn('sip connection state on make call',this.sipConnectionService.isConnected())
    if (!this.sipConnectionService.isConnected()) {

      this.sipConnectionService.initialiseConnection().then(state => {
        if (state === RegistererState.Registered) {
          this.toastr.clear()
          this.invite(type, from, to, agentsipuri, callerSession)
        }
        else {
          this.toastr.info("Error on connecting sip for outbound...")
        }

      })
    }
    else {

      this.invite(type, from, to, agentsipuri, callerSession)
    }
  }
  closeCommonSidePanel(){
    const sidePanelConstants:sidePanelConstants ={EventName:CommonPanelEvents.ClosePanel}
    this.commonService.dispatch({type:AppEventType.CommonPanelEvent,payload:sidePanelConstants});
  }

  private get activeCallerSession() {
    return this.callCenterShared.activeCallerSession
  }

  private get sipDetails() {
    return this.callCenterShared.sipDetails
  }

  async invite(type: string, from, to, agentsipuri,callerSession) {

    to=to.replace(/\D+/g, "");
  
    let tis = this;
    var target;
    target = UserAgent.makeURI("sip:" + to + '@' + agentsipuri.split('@')[1]);

    const customHeaders = ['X-Send-As:' + from, 'X-To-Number:' + to, 'X-Call-Type:' + type];

    this.connection = this.callCenterShared.connection
    const session: any = new Inviter(this.connection, target, {
      extraHeaders: customHeaders
    });

    tis.allowClearCallQ = false;

    this.callto_sip_uri = type == 'contact' ? "sip:" + to + '@' + agentsipuri.split('@')[1] : to
    this.updateCallPush(callerSession.customValues.pushid,session)
    this.ring();
  }

  listenToSessionChanges(callerSession:any)
  {

    callerSession.session.stateChange.addListener((newState: SessionState) => {

      switch (newState) {
        case SessionState.Initial:
          break;
        case SessionState.Establishing:
          break;
        case SessionState.Established:

        this.setCallLog(callerSession)
          this.callCenterShared.handleMic(true);
          if(!this.activeCallerSession.customValues.isAgentCall){
            this.stopRing();
          }
          const remoteStream = new MediaStream();
          this.activeCallerSession.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
            if (receiver.track) {
              remoteStream.addTrack(receiver.track);
            }
          });

          this.mediaElement.srcObject = remoteStream;
          this.mediaElement.play();
          
          break;

        case SessionState.Terminating:

          break
        // fall through
        case SessionState.Terminated:
          this.callCenterShared.disconnectMic()
          if (!this.isOnHold(callerSession)) {//only enters if not on hold
            if (this.callCenterShared.sipDetails.status == 'On Call') {
              this.updateLastCallTime(callerSession.session);
            }

            //this.callCenterService.isActiveCallOutbound = false;
            this.allowClearCallQ = true;
            this.callCenterShared.clearCompletedCallsfromCallQ();
            this.callDeclineTone();
            this.stopRing();
            // if(callerSession.customValues.isAgentCall){
            //   this.stopRing()
            // }
            // else{
            //   this.mediaElement.pause();
            //   this.mediaElement.srcObject = null;
            // }
            this.setOutBoundNumber('')
            // this.isCallTerminated.next(true)
            if (!callerSession.customValues.isCallOnHold) {
              this.callCenterShared.stopCallTimer(callerSession)
            }
            this.callCenterShared.disconnectMic()
            if (!callerSession.customValues.isCallOnHold) {
                this.callCenterShared.callLog = {
                sid : callerSession.customValues.pushid,
                call_state : 'terminated'}
            }
          }
          break;
        default:
          break;

      }
    })
    const inviteOptions: InviterInviteOptions = {
      requestDelegate: {
        onAccept: (response) => {
          console.log("accept responce", response.message);
          console.log("Positive response = " + response);
        },
        onReject: (response) => {
          console.log("Negative response = " + response);
        },
      },
      sessionDescriptionHandlerOptions: {
        constraints: {
          audio: true,
          video: false,

        },
      },
    }

   callerSession.session.invite(inviteOptions).then((request: OutgoingInviteRequest) => {

      console.log("Successfully sent INVITE");
      console.log("INVITE request = ", request);

      // Handle key events to send DTMF tones

    })
      .catch((error) => {
        console.error(' Failed to INVITE');
        console.error(error.toString());
      });
  }

  onNotificationEstablished(callerSession)
  {
    this.stopRing();
    // const remoteStream = new MediaStream();
    // this.activeCallerSession.session.sessionDescriptionHandler.peerConnection.getReceivers().forEach((receiver) => {
    //   if (receiver.track) {
    //     remoteStream.addTrack(receiver.track);
    //   }
    // });

    // this.mediaElement.srcObject = remoteStream;
    // this.mediaElement.play();
    // if(callerSession.customValues.isAgentCall){
    //   this.stopRing()
    // }
    // else{
    //   this.mediaElement.pause();
    //   this.mediaElement.srcObject = null;
    // }
    this.callAcceptTone();
    if (!callerSession.customValues.elapsedTime) {
      callerSession.customValues.elapsedTime = 0
    }
    this.callCenterShared.startCallTimer(callerSession)
    this.callCenterShared.lastDialledNumber = ""
    this.callCenterShared.showDialer = false
        
    
  }


  getSessionStr(obj) {
    let cache = [];
    let str = JSON.stringify(obj, function (key, value) {
      if (typeof value === "object" && value !== null) {
        if (cache.indexOf(value) !== -1) {
          // Circular reference found, discard key
          return;
        }
        // Store value in our collection
        cache.push(value);
      }
      return value;
    });
    cache = null; // reset the cache
    return str;
  }

  updateLastCallTime(endedCall) {

    // const isCallPresent = this.acceptCallQ.includes(endedCall);
    // if (isCallPresent) {
    //   let date = new Date();
    //   let payload: UserStatusUpdateRequest = new UserStatusUpdateRequest();
    //   payload.call_end_time = this.datePipe.transform(date, 'yyyy-MM-dd\'T\'HH:mm:ss.SSSZZZZZ')
    //   this.Subs.sink = this.callCenterService.updateLastCallTime(payload).subscribe(responds => { }
    //     , (err) => {
    //       console.log(err);
    //     });
    // }
  }
  callAcceptTone() {
    this.acceptTone.src = "../../../../assets/ringtone/pickup tone.mp3"
    this.acceptTone.play();
  }

  callDeclineTone() {
    this.declineTone.src = "../../../../assets/ringtone/decline tone.mp3"
    this.declineTone.play();
  }

  ring() {
    if (this.ringAudio) {
      this.ringAudio.pause();
    }
    //if (this.isOutgoingCall && !this.isActiveCall && (localStorage.getItem('isOnCall') == 'false')) {
    this.ringSound();
    // } else {
    //   this.ringAudio.pause();
    // }
  }

  ringSound() {

    this.ringAudio.src = "../../../../assets/ringtone/outbound-call_tone.mp3"
    this.ringAudio.muted = false;
    this.ringAudio.loop = true;
    this.ringAudio.play();
  }

  private get callQ() {
    return this.callCenterShared.callQ
  }

  private get initialCallQ() {
    return this.callCenterShared.initialCallQ
  }
  getCurrentCallId(callerSession,accept:boolean = false,hold:boolean = false,retryIfFailed:boolean = false) {
   setTimeout(() => {
      this.subs.sink = this.callCenterService.getCallIdOutbound(this.callCenterShared.sipDetails.sip_uri, this.callto_sip_uri).subscribe((resp) => {
        //session.customValues.call_id = res.child_call_id 
        if(resp.http_status == 200)
          {
            if(callerSession.callDetails.parent_call_id == resp.data.result.parent_call_id)
              {
               // if(resp.data.result.child_call_id != null)
                //  {
                    callerSession.callDetails.call_id = resp.data.result.child_call_id
                //  }
    
            
                callerSession.callDetails.parent_call_id = resp.data.result.parent_call_id
                callerSession.callDetails.project_id = resp.data.result.project_id
    
                console.log('outboundcallid',callerSession.callDetails.call_id)
                this.callCenterShared.updateCallQ(callerSession,'outbound')
                console.log('callq_getid',this.callQ)
                console.log('getcallidresp',resp) 
                if(hold)
                  { 
                    this.holdCall(callerSession)
                  }

                if(accept)
                  {
                    callerSession.customValues.call_answered = true
                    this.callCenterShared.updateCallQ(callerSession,'outbound') 
                    this.onNotificationEstablished(callerSession)
                    this.callCenterShared.outboundParentIds = []
                    this.callCenterShared.callLog = {
                      sid : callerSession.customValues.pushid,
                      call_state : 'established'}
                  }   
                // if(callerSession.callDetails.call_id == null && !hold && retryIfFailed)
                //   {
                //     console.warn('repeated get_call_id API')
                //     this.getCurrentCallId(callerSession) 
                //   }

              }
              else if(resp.data.result.parent_call_id == null,retryIfFailed)
                {
                  console.warn('repeated get_call_id API')
                  this.getCurrentCallId(callerSession,accept,hold)
                }
              else
              {
                console.warn('parent id missmatch'
                  +'push:'+callerSession.callDetails.parent_call_id+
                  +'api:'+resp.data.result.parent_call_id

                 )
              }
              

           
          }
          else{
            this.toastr.error('failed to get ouboundcallid '+resp.message[0])
          }
       
      })
   }, 3000);

  }


  setOutBoundNumber(newValue: string): void { //to set number for outbound call
    this.number.next(newValue);
  }

  getNumber(): Observable<string> {  //to get number for outbound call
    return this.number.asObservable();
  }

  updateSendAsNumberWithAreaCd(code: string) {
    const data = { "area_code": code }
    return this.http.post<any>(`${this.baseURL}/change_send_as_number/`, data)
  }

  get_DetailsofNonCallCenterNumber(callersession: any) {

    let fromNumber = callersession.customValues.callFrom.includes('+')? callersession.customValues.callFrom:
                    '+1'+callersession.customValues.callFrom//'+1'+tono


    let toNumber = callersession.customValues.callTo.includes('+')? callersession.customValues.callTo:
    '+1'+callersession.customValues.callTo//'+1'+fromno

    this.subs.sink = this.callCenterService.getNonCallCenterDetailsOutbound(fromNumber.replace(/[^0-9+]/g, ''),toNumber.replace(/[^0-9+]/g, '')).subscribe(resp => {

      // this.currentCallDetails.contactDetails = resp
      if(resp.http_status == 200)
        {
          callersession.callDetails = resp.data.result
          callersession.callDetails.parent_call_id = null
          //this.getCurrentCallId(callersession)
           this.callCenterShared.updateCallQWithPushid(callersession)
          //console.log('outboundcallersession',callersession)
          //this.setCallLog(callersession)
        
        }

    })
  }

  pushCallerSession(callerSession)
  {
    this.callCenterShared.initiatedOutboundCall = false
    const currentValue = this.callQ;
    const updatedValue = [...currentValue, callerSession];
    this.callCenterShared.callQ = updatedValue;
  }

  updateCallPush(pushid,session)
  {
    var callSessionToUpdate = this.callQ.find(x => x.customValues.pushid == pushid)
    if(callSessionToUpdate)
      {
        callSessionToUpdate.session = session
      }
    if(this.currentCallType == 'contact')
      {
        this.get_DetailsofNonCallCenterNumber(callSessionToUpdate);
      }
    else(this.currentCallType == 'sip')
      {
        this.get_SIPCallDetails(callSessionToUpdate)
      }

      this.listenToSessionChanges(callSessionToUpdate)
  }

  isOnHold(callerSession) {
    if (callerSession.customValues.isCallOnHold) {
      return true;
    } else {
      return false;
    }
  }

  get_SIPCallDetails(callerSession: any) {
    let request = { from_sip: this.callCenterShared.sipDetails.sip_uri, to_sip: this.callto_sip_uri.startsWith('sip:') ? this.callto_sip_uri.slice(4) : this.callto_sip_uri}

    this.subs.sink = this.callCenterService.getSIPDetailsbyUserName(request).subscribe(response => {
      if(response.http_status == 200)
        {
          callerSession.callDetails.from_sip_user = response.data.result.from_sip_user;
          callerSession.callDetails.to_sip_user = response.data.result.to_sip_user;
          //this.getCurrentCallId(callerSession)
          console.log('tosipuser',callerSession.callDetails.to_sip_user)
          //this.setCallLog(callerSession)
        }
      else
       {
        response.message.forEach((err_message)=>{
          this.toastr.error(err_message);
        })
       } 

    })
  }
  stopRing() {
    this.ringAudio.pause()
  }
  clearCallQ() {
    this.callCenterShared.callQ = [];
  }

  holdCall(Callersession: any,retryHoldIfFailed: boolean = false,retryCount:number=0) {
 
    const call_Details = Callersession.callDetails;
    const is_hold = Callersession.customValues.isCallOnHold;

    if (is_hold) // if user unhold call
    {
      if (this.callCenterShared.activeCallerSession) {

        if (this.callCenterShared.activeCallerSession.callDetails.call_id != call_Details.call_id) {

          this.toastr.warning("Please hold current call")
          return
        }
      }
    }
    if(call_Details.call_id == null && is_hold)
      {
        if(retryHoldIfFailed)
          {
            console.warn('retrying get_call_id')
            this.toastr.error('Failed to hold call please retry')
            this.getCurrentCallId(Callersession,false,true)
            return
          }
          else
          {
            return;
          }
      }

    //* hold current active call
    if (Callersession.session.state == 'Established' && !is_hold) {
      this.callCenterShared.initiatedOutboundCall = false  
      let payload = {
        call_sid: call_Details.call_id,
        sip: this.callCenterShared.sipDetails.sip_uri,
        to_number: call_Details.customer_number,
        project_id:call_Details.project_id,
        call_type  :"outbound",
        call_recording_enabled: "true"
      };
      this.callCenterShared.setAutoAcceptableCallIds(call_Details.call_id)
      this.subs.sink = this.callCenterService.putCallOnHold(payload).subscribe(response => {
        if(response.http_status == 200)
          {
            Callersession.customValues.isCallOnHold = true;
            if(document.visibilityState === "hidden" ){
              this._fireMessagingService.showUnholdCallNotification(call_Details)
            }
          }else{
            retryCount++
            if(retryCount<=1){
              this.holdCall(Callersession,false,retryCount)
              console.warn("hold call failed-"+ retryCount);
            }else{
              this.handleHoldFailed(Callersession,true)
            }
          }
      },
      (error) => {
        retryCount++
        if(retryCount<=1){
          this.holdCall(Callersession,false,retryCount)
          console.warn("hold call failed-"+ retryCount);
        }else{
          this.handleHoldFailed(Callersession ,true)
        }
      });
    }else{//* release from hold
      if (is_hold && Callersession.session.state == 'Terminated') {
        if(!this.sipConnectionService.isConnected()){
          console.warn("--SIP NOT CONNECTED--");
          try {
            this.sipConnectionService.initialiseConnection().then((state) => {
              if (state === RegistererState.Registered) {
                console.warn('---Sip registered while unhold call---');
                this.sipInboundFunctionService.inviteCall();
                this.unHoldApi(Callersession,call_Details);//Api call for unhold
              }
              else{
                this.toastr.error('failed to connecting sip.')
              }
            })    
          }
          catch (e) {
            this.toastr.error('Failed to initialize sip connection');
          }
        }else{
          this.sipInboundFunctionService.inviteCall();
          this.unHoldApi(Callersession,call_Details);
        }
      }
      /**
      * if call failed hold and showing as hold without terminate
      */
      else if(is_hold && Callersession.session.state == 'Established')
      {
        this.handleHoldFailed(Callersession,false)
        // callerSession.customValues.isCallOnHold = false
        // //this.callcenterSharedService.handleMic(true)
        // this.callcenterSharedService.muteCall(false)
        
      }
    }
  }

  private unHoldApi(Callersession: any, call_Details:any){
    let payload = {
      call_sid: call_Details.call_id,
      to_number: call_Details.users_number,
      sip_username: 'sip:' + this.callCenterShared.sipDetails.sip_uri,
      call_center_enabled: this.iscallcenterEnabled,
      project_id:call_Details.project_id,
      call_type  :"outbound",
      call_recording_enabled: "true"
    };
    //Callersession.customValues.isCallOnHold = true
    this.subs.sink = this.callCenterService.releaseCallFromHold(payload).subscribe((response) => {
      if(response.http_status == 422){            
        this.toastr.warning('Caller hung up');
        this.callCenterShared.removeCurrentCallsfromCallQ(call_Details.call_id)
        this.callCenterShared.checkQandCloseCallCenterPanel();
      }else if(response.http_status == 200){
        Callersession.customValues.isCallOnHold = false
        if(document.visibilityState === "hidden" ){
          this._fireMessagingService.showHoldCallNotification(call_Details)
        }
      }else{
        response.message.forEach((err_message)=>{
          this.toastr.error(err_message);
        });
      }
    },
    (error) => {
      console.log('error', error);
    });
  }

  handleHoldFailed(callerSession:any,value:boolean)
  {
    callerSession.customValues.isCallOnHold = value
    this.callCenterShared.muteCallHold(callerSession,value)
  }
  endCall(Callersession) {
    if (Callersession.session.state == 'Established') {
      Callersession.session.bye().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to end call')
        this.removeCallFailed(Callersession)
        this.callCenterShared.disconnect()
      })
    }
    else if(Callersession.session.state == 'Establishing' || Callersession.session.state == 'Initial')
      {
        Callersession.session.cancel();
        this.callCenterShared.initiatedOutboundCall = false
      }
     else {
      let payload = {
        call_sid: Callersession.callDetails.call_id,//this.call_Details.call_id,
        to_number: null,// call_Details.call_to
        username: this.callCenterShared.sipDetails.sip_uri,
        project_id:Callersession.callDetails.project_id,
        call_type  :"outbound",
        call_recording_enabled: "true"
      };
      this.subs.sink = this.callCenterService.endCallFromHold(payload).subscribe(response => {

        if(response.http_status == 200)
          {
            Callersession.customValues.isCallOnHold = false;
            this.callCenterShared.clearCompletedCallsfromCallQ(false);
          }
        else
          {
            this.toastr.error("Failed to end call,"+response.message[0]);
            this.removeCallFailed(Callersession)
          }
       
      },
        (error) => {
          this.toastr.error("Failed to end call");
          this.removeCallFailed(Callersession)
        });
    }
  }
  removeCallFailed(callerSession)
  {
    this.callDeclineTone()
    this.callCenterShared.removeFromCallQ(callerSession.callDetails.call_id)
  }

  rejectCall(callerSession: any) {

    if(callerSession.session.state == "push")
      { 
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callCenterShared.ring();
        }
        else {
          this.callCenterShared.stopRing();
        }
      }
    if (callerSession.session.state == 'Initial') {
      callerSession.session.cancel().then(() => {
        this.callDeclineTone();
      }).catch(() => {
        this.toastr.error('Failed to reject call')
        this.removeCallFailed(callerSession)
        if (this.initialCallQ?.length > 0) {
          this.callCenterShared.ring();
        }
        else {
          this.callCenterShared.stopRing();
        }
      })
    }

  }
  setCallLog(callerSession:any)
  {
    console.log('--callsession_log',callerSession)
    let _callLog: any = {
      sid :callerSession.customValues.pushid, //using pushid as temparary sid
      company_users_name: this.sipDetails.associated_username,
      call_from: this.sipDetails.agent_name,
      call_to: callerSession.customValues.callTo,
      date_created: new Date(),
      recording_url_lis:null,
      accepted_by_name:  callerSession.customValues.isAgentCall? 
      callerSession.callDetails?.to_sip_user?.name:callerSession.callDetails.users_name,
      suborg_name: this.getCurrentSuborgName,
     // incoming_route_name: callerSession.callDetails.incoming_route_name,
      call_from_image:callerSession.customValues.isAgentCall? callerSession.callDetails?.from_sip_user?.call_from_image:
                      callerSession.callDetails.call_from_image,  
      call_to_image:callerSession.customValues.isAgentCall? callerSession.callDetails?.to_sip_user?.call_to_image:
                      callerSession.callDetails.call_to_image,  

      status:'In progress',
      direction:'outbound',
      isAccepted : false

    }
    this.callCenterShared.callLog = _callLog;

  }

  transferCall(callerSession: any, transfer_option:string, toUserId:number) {
    if(callerSession?.session?.state == 'Established'){
      let payload: TransferCallPayload = new TransferCallPayload();
      payload.call_sid = callerSession.callDetails.call_id;
      payload.project_id = callerSession.callDetails.project_id;
      payload.transfered_to = toUserId;
      payload.transfered_by = +localStorage.getItem(AppConstants.USER_ID);
      if(callerSession.customValues.isAgentCall){
        payload.from_number = this.callCenterShared.sipDetails.sip_uri;
        payload.to_number = this.callto_sip_uri.startsWith('sip:') ? this.callto_sip_uri.slice(4) : this.callto_sip_uri;
        payload.call_recording_enabled = "false";
        payload.incoming_route_name = "";
        payload.call_type = "agent_outbound_transfer";
      }else{
        payload.from_number = callerSession.callDetails.simplyfuse_number;
        payload.to_number = callerSession.callDetails.users_number;
        payload.call_recording_enabled = callerSession.callDetails.call_recording_enabled;
        payload.incoming_route_name = callerSession.callDetails.simplyfuse_number_org_name;
        payload.call_type = "customer_outbound_transfer";
      }
      payload.transfer_option = transfer_option;
      
      this.subs.sink = this.callCenterService.transferCall(payload).subscribe({
        next:resp => {
          if(resp.http_status == 200 && resp.data?.result?.status){
            this.sipInboundFunctionService.showTransferList = false;
            const callTransferredTo = resp.data?.result?.call_transfered_to || 'Agent';
            this.toastr.success(`Call has been successfully transferred to ${callTransferredTo}`);
          }
          else{
            this.toastr.error("Failed to transfer call");
          }
        },
        error:err => {
          this.toastr.error("Failed to transfer call","Internal server error");
        }
      })
    }else{
      this.toastr.error("No active call to transfer","Error");
      return;
    }
  }

  destroy() {
    // this.subject.complete();
    this.subs.unsubscribe();
  }

}
